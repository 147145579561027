import { doGet, doPost, doPut } from "../common/axiosUtil";

export const postLoan = async (
  amount,
  monthly,
  account_id,
  credit_offer,
  grace_period,
  term,
  pay_out_date,
  interest
) => {
  const post = await doPost("v1/loan/", {
    credit_amount: amount,
    monthly_repayment: monthly,
    account: account_id,
    credit_offer,
    grace_period,
    term,
    activation_date: pay_out_date,
    interest,
  });
  return post;
};

export const renewCreditOffer = async () => {
  const put = await doPut("v1/credit_offer/renew/");
  return put;
};

export const getActivationDateConfiguration = async (
  credit_amount,
  currency,
  credit_offer
) => {
  const get = await doGet("v1/loan/activation_date_configuration/", {
    credit_amount,
    currency,
    credit_offer,
  });
  return get;
};
