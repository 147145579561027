import { useContext } from "react";
import { UserContext } from "../../hooks/useWhoAmI";
import useAPI from "../../hooks/useAPI";
import { Item } from "./item";
import Loading from "../loading";

const List = () => {
  const { me, loading: user_loading } = useContext(UserContext);
  const [result, loading, error] = useAPI({
    me,
    path: "v1/loan/?status=accepted",
  });
  if (loading || user_loading) {
    return <Loading />;
  }

  if (error) {
    return <p>An error occurred, please try again later.</p>;
  }

  if (result.length === 0 || !result) {
    return (
      <p className="flex flex-col p-2 text-float-grey-1">
        You don't have any loans. Apply for a loan above to get started.{" "}
      </p>
    );
  }
  return (
    <div className="w-full h-full overflow-auto">
      <table className="w-full mb-4">
        <tr className="text-float-grey-1 sticky top-0 bg-white">
          <th className="font-normal p-2 text-left text-sm md:text-base">
            Loan number
          </th>
          <th className="font-normal p-2 text-left text-sm md:text-base">
            Amount
          </th>
          <th className="font-normal p-2 text-left text-sm md:text-base">
            Activation date
          </th>
          <th className="font-normal p-2 text-left text-sm md:text-base">
            Interest
          </th>
          <th className="font-normal p-2 text-left text-sm md:text-base">
            Repaid
          </th>
        </tr>
        {result.map((i, index) => (
          <Item
            key={index}
            index={index}
            country={me.selected_company.country || "en"}
            {...i}
          />
        ))}
      </table>
    </div>
  );
};

export default List;
