import { useContext } from "react";
import { UserContext } from "../../hooks/useWhoAmI";
import useAPI from "../../hooks/useAPI";
import Loading from "../loading";
import Line from "../line";

const AccountOverview = () => {
  const { me, loading: user_loading } = useContext(UserContext);
  const [result, loading, error] = useAPI({ me, path: "v1/loan/overview/" });

  const getInterestRate = (interest) => {
    if (interest?.toString().length > 2) {
      return interest.toFixed(2);
    }
    return interest;
  };

  if (loading || user_loading) {
    return <Loading />;
  }

  if (error) {
    return <p>An error occurred, please try again later.</p>;
  }
  const overview = result ? result[0] : {};

  return (
    <div className="px-4 flex flex-col p-4">
      <div className="flex flex-row">
        <div className="flex flex-col">
          <p className="text-sm md:text-base">Your Float credit rating</p>
          <div className="flex flex-row">
            <p className="font-semibold w-8 text-sm md:text-base">
              {overview
                ? overview.credit_rating.toLocaleString("en-US", {
                    minimumFractionDigits: 1,
                    useGrouping: false,
                  })
                : 0}
            </p>
            <div className="my-auto ml-2 xl:ml-4">
              <Line
                width={140}
                progress={(overview?.credit_rating - 1) * 0.25}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-4 xl:ml-8">
          <p className="xl:whitespace-nowrap text-sm md:text-base">
            Current interest rate
          </p>
          <p className="font-semibold text-sm md:text-base">
            {getInterestRate(overview?.interest)} %
          </p>
        </div>
      </div>
      <div className="flex flex-col py-4 w-4/5">
        <p className="text-sm md:text-base">Repaid vs. borrowed</p>
        <div className="flex flex-row">
          <p className="font-semibold w-8 text-sm md:text-base">
            {overview?.repaid} %
          </p>
          <div className="my-auto xl:ml-4 ml-2">
            <Line
              width={281}
              progress={overview?.repaid / 100}
              bgColor="#EFE6FB"
              lineColor="#6F3F91"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-4/5">
        <p className="text-sm md:text-base">Used credit</p>
        <div className="flex flex-row">
          <p className="font-semibold w-8 text-sm md:text-base">
            {overview?.used_credit} %
          </p>
          <div className="my-auto xl:ml-4 ml-2">
            <Line
              width={281}
              progress={overview?.used_credit / 100}
              bgColor="#FFC29C"
              lineColor="#342720"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountOverview;
