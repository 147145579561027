const Line = ({
  bgColor = "#DAF5B4",
  lineColor = "#79AA36",
  width = 140,
  progress,
}) => {
  return (
    <svg
      width={width}
      height="9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="4"
        y1="4"
        x2={width - 4}
        y2="4.94817"
        stroke={bgColor}
        strokeWidth="8"
        strokeLinecap="round"
      />
      <line
        x1="4"
        y1="4"
        x2={progress > 0 ? width * progress - 4 * progress : "4"}
        y2="4.94817"
        stroke={lineColor}
        strokeWidth="8"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default Line;
