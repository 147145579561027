import { Icon } from "@iconify/react";
import Line from "../line";
import { formatDate } from "../../common/utils";

export const Item = ({
  repaid_amount,
  repaid_percentage,
  credit_offer,
  credit_amount,
  index,
  country,
  activation_date,
  interest,
  agreement,
  loan_reference,
}) => {
  const formatter = new Intl.NumberFormat(country);
  const loanAmount = `${formatter.format(credit_amount)} ${
    credit_offer.currency
  }`;

  const date = formatDate(activation_date);
  const getInterestRate = () => {
    if (interest.toString().length > 2) {
      return interest.toFixed(2);
    }
    return interest;
  };
  const getLastTwoCharacters = (str) => {
    if (str) {
      return str.slice(-2);
    }
    return "";
  };

  return (
    <tr className="text-black border-b-gray-100 border-b-2">
      <td className="px-2">
        {agreement ? (
          <a href={agreement} target="_blank" rel="noreferrer">
            <div className="flex flex-row">
              <div className="flex">
                <p className="font-bold text-sm md:text-base underline">{`#${getLastTwoCharacters(
                  loan_reference
                )}`}</p>
              </div>
              {/* <iconify-icon icon="mingcute:pdf-line"></iconify-icon> */}
              <div className="flex ml-2 mx-auto">
                <Icon icon="mingcute:pdf-line" className="flex" width="20" />
              </div>
            </div>
          </a>
        ) : (
          <p className="font-bold text-sm md:text-base">{`#${getLastTwoCharacters(
            loan_reference
          )}`}</p>
        )}
      </td>
      <td className="px-2">
        <p className="font-bold text-sm md:text-base">{loanAmount}</p>
      </td>
      <td className="px-2">
        <p className="font-bold text-sm md:text-base">{date}</p>
      </td>
      <td className="px-2">
        <p className="font-bold text-sm md:text-base">{`${getInterestRate()} %`}</p>
      </td>
      <td className="px-2">
        <div className="tooltip">
          <span className="text-small">{`${repaid_percentage} %`}</span>
          <Line
            width={80}
            bgColor={repaid_percentage === 100 ? "#DAF5B4" : "#F9DD8E"}
            lineColor={repaid_percentage === 100 ? "#79AA36" : "#F4C400"}
            progress={repaid_percentage / 100}
          />
        </div>
      </td>
    </tr>
  );
};
